#bt_notification {
  .bt {
    &-content {
      @include swap_direction(padding, 15px);
      position: relative;
      border: 1px solid $color-black;
      background-color: $color-white;
      z-index: 1001;
      font-size: 12px;
      line-height: 16px;
      transition: all 5s;
      -webkit-transition: all 5s;
      border-width: 10px;
      color: $color-dark-gray;
      a {
        color: $color-white;
        &.bt-close-link {
          position: absolute;
          top: 10px;
          #{$rdirection}: 10px;
          display: block;
          width: 15px;
          height: 15px;
          background: url('/media/images/cookies/close_x.png') 50% 50% no-repeat;
        }
      }
      .btn {
        @include swap_direction(padding, 13px 15px 14px 15px);
        background: $color-black;
        min-width: 140px;
        display: inline-block;
        border-radius: 4px;
        line-height: 1;
        width: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        vertical-align: middle;
        margin-top: 10px;
      }
    }
    &-bottom-right {
      position: fixed;
      #{$rdirection}: 10px;
      width: 305px;
      max-width: 100%;
      .bt-cookie-section {
        .bt-content {
          border: none;
          padding: 0;
        }
      }
    }
    &-pp-section {
      margin-#{$rdirection}: 20px;
    }
  }
}

#bt_settings {
  .bt-content {
    @include swap_direction(padding, 15px);
    border: 2px solid $color-light-gray;
    background-color: $color-white;
    z-index: 1001;
    font-size: 12px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    .bt-intro {
      h2 {
        text-transform: none;
        color: $color-light-gray;
      }
      &-copy {
        p {
          margin-top: 1em;
        }
      }
    }
    .slide {
      @include swap_direction(margin, 20px 0);
      position: relative;
      overflow: hidden;
      margin-bottom: 20px;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
      }
    }
    .slide-toggle {
      position: relative;
      overflow: hidden;
      width: 292px;
      margin: 0 auto;
      background: transparent url('/media/images/cookies/m_all-off.png');
      @include breakpoint($medium-up) {
        width: 525px;
        background-size: contain;
        background: transparent url('/media/images/cookies/all-off.png') 50% 100% no-repeat;
        margin-bottom: 20px;
      }
      li {
        cursor: pointer;
        float: $ldirection;
        width: 96px;
        height: 157px;
        list-style-type: none;
        @include breakpoint($medium-up) {
          width: 174px;
          height: 148px;
        }
        span {
          display: none;
        }
      }
    }
    .mask {
      position: absolute;
      overflow: hidden;
      top: 0px;
      #{$ldirection}: 0px;
      width: 0px;
      border-right: 1px solid $color-light-gray;
      transition: width 1s;
      -webkit-transition: width 1s;
      .slide-toggle {
        background-image: url('/media/images/cookies/m_all-on.png');
        @include breakpoint($medium-up) {
          width: 525px;
          background-size: contain;
          background: transparent url('/media/images/cookies/all-on.png') 50% 100% no-repeat;
        }
      }
      .drag {
        position: absolute;
        bottom: 12px;
        #{$rdirection}: 45px;
        width: 26px;
        height: 11px;
        background: transparent url('/media/images/cookies/icon_arrow.png') 50% 100% no-repeat;
        @include breakpoint($medium-up) {
          #{$rdirection}: 100px;
          bottom: 32px;
        }
      }
    }
    .bt-privacy-info {
      display: none;
      overflow: hidden;
      h3 {
        @include swap_direction(padding, 15px 1em 15px 40px);
        background-position: 10px 50%;
        background-repeat: no-repeat;
        background-color: $color-white-smoke;
      }
      .bt-privacy-will {
        h3 {
          background-image: url('/media/images/cookies/icon_safe_lock.png');
        }
        ul {
          li {
            background-image: url('/media/images/cookies/icon_safe_thick.png');
          }
        }
      }
      .bt-privacy-willnot {
        h3 {
          background-image: url('/media/images/cookies/icon_unsafe_lock.png');
        }
        ul {
          li {
            background-image: url('/media/images/cookies/icon_unsafe_thick.png');
          }
        }
      }
      div {
        width: 100%;
        margin-#{$rdirection}: 2%;
        float: none;
        @include breakpoint($medium-up) {
          width: 48%;
          float: $ldirection;
        }
        ul {
          @include swap_direction(margin, 1em);
          padding-#{$ldirection}: 0;
          li {
            @include swap_direction(margin, 0.8em 0);
            padding-#{$ldirection}: 30px;
            background-position: 0 50%;
            background-repeat: no-repeat;
            list-style-type: none;
          }
        }
      }
      &.selected {
        display: block;
      }
    }
    &-desktop {
      @include swap_direction(padding, 1em);
      height: 100%;
    }
  }
}

.tealium {
  display: none !important;
}

.customer-service {
  &__content {
    a {
      word-break: break-word;
    }
  }
}
