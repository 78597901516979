.shipping {
  .checkout {
    &__content {
      .checkout-panel {
        &--shipping {
          .checkout-panel {
            &__header {
              margin: 0 0 0.5em;
            }
            &__content {
              padding: 1em 0;
              border-bottom: 1px solid $color-light-gray;
              &--shipping-edit-address,
              &--billing-edit-address {
                .form-item {
                  &.title,
                  &.radio-buttons {
                    width: 100%;
                  }
                }
                .address_controls {
                  position: relative;
                  margin: 0 0 10px;
                  select {
                    @include breakpoint($medium-up) {
                      width: 50%;
                    }
                  }
                  .button--secondary {
                    position: absolute;
                    top: 0;
                    right: 0;
                    min-width: 150px;
                    margin-right: 0;
                    &,
                    &:hover,
                    &:focus,
                    &:active {
                      @include breakpoint($med-small-down) {
                        position: initial;
                        margin: 10px 10px 0 0;
                        padding: 0;
                        border: none;
                        text-decoration: underline;
                        font-size: 16px;
                        color: $color-green;
                        background: $white;
                      }
                    }
                    &.edit-address {
                      top: 50px;
                    }
                  }
                }
              }
              &--billing-edit-address {
                .address_controls {
                  .button--secondary {
                    &,
                    &:hover,
                    &:focus,
                    &:active {
                      @include breakpoint($med-small-down) {
                        margin: 0;
                        padding: 0;
                      }
                    }
                    &.edit-address {
                      top: 0;
                    }
                  }
                }
              }
              .new-address {
                div:first-child {
                  margin-bottom: 20px;
                }
              }
              .continue-button-wrapper {
                input {
                  @include breakpoint($med-small-down) {
                    width: 100%;
                    margin: 0;
                  }
                }
              }
            }
          }
        }
        &--shipmethod {
          .ship-method-group-label {
            margin-bottom: 0;
          }
        }
        .address-form {
          .field-container {
            display: block;
            width: 100%;
            overflow: auto;
          }
        }
        .checkout-buttons-content {
          padding: 20px 0;
          overflow: auto;
        }
      }
    }
  }
}

.address-overlay {
  width: 100%;
  @include breakpoint($medium-up) {
    width: 750px;
  }
  padding: 30px 35px;
  .address-form {
    .field-container {
      overflow: auto;
      .form-item {
        @include breakpoint($medium-up) {
          float: left;
          padding-right: 20px;
        }
        &,
        .postal-code,
        .city {
          @include breakpoint($medium-up) {
            width: 50%;
          }
        }
        &.company_name {
          @include breakpoint($medium-up) {
            width: 100%;
          }
        }
        input {
          width: 100%;
        }
      }
    }
  }
}
