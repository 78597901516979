.samples-page {
  .samples-content {
    &__samples {
      &--button-container {
        position: relative;
        padding-top: 0;
        z-index: 5;
      }
    }
    .details {
      @include breakpoint($med-small-down) {
        padding-top: 10%;
      }
      .product-brief__title {
        min-height: 0;
        @include breakpoint($medium-up) {
          min-height: 100px;
        }
      }
    }
    ul {
      &.product-list {
        .product:last-of-type {
          @include breakpoint($med-small-down) {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.active-panel-samples {
  .site-container {
    .samples-page {
      .sticky-checkout-button {
        @include breakpoint($landscape-down) {
          position: fixed;
          width: 100%;
          padding: 15px;
          box-shadow: 0 -3px 6px $color-lightest-grey;
        }
        .button {
          @include breakpoint($landscape-down) {
            height: 47px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
