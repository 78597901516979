.customer-service {
  &-privacy-policy {
    .main_ul_nav,
    .sub1_ul_nav,
    .sub2_ul_nav,
    .sub3_ul_nav {
      margin-#{$ldirection}: 25px;
    }
    .main_ul_nav {
      list-style: disc;
      padding-bottom: 1.5em;
      .sub1_ul_nav {
        list-style: circle;
        .sub2_ul_nav {
          list-style: square;
          .sub3_ul_nav {
            list-style: disc;
          }
        }
      }
    }
    .pp_table {
      @include swap_direction(border, 1px solid $color-dark-gray);
      width: 100%;
      margin-bottom: 1.5em;
      td {
        @include swap_direction(border, 1px solid $color-dark-gray);
        text-align: center;
      }
    }
    .pp-headline {
      font-weight: bold;
      text-decoration: underline;
    }
    .pp-subheadline {
      font-weight: bold;
    }
    .pp-subheadline-line {
      text-decoration: underline;
    }
  }
  &-returns-page {
    ul {
      li {
        @include swap_direction(padding, 2px);
        display: block;
        margin-#{$ldirection}: 20px;
      }
    }
  }
  &__collapsible {
    .state {
      display: none;
    }
  }
  &__navigation {
    margin: 0;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  margin-bottom: 10px;
  &.photo-tip {
    padding-top: 5px;
  }
  a {
    text-decoration: underline;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    @include swap_direction(padding, 10px);
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    color: $color-medium-gray;
    border: 1px solid $color-light-gray;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    #{$ldirection}: 0;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext-under {
    top: 100%;
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      #{$ldirection}: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-medium-gray transparent;
    }
  }
  .tooltiptext-over {
    bottom: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      #{$ldirection}: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: $color-medium-gray transparent transparent transparent;
    }
    .waitlist-legal-copy {
      line-height: 17px;
      font-size: 13px;
      text-align: $ldirection;
    }
  }
  &:hover {
    .tooltiptext-under,
    .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
  &.tooltip-btm-left-margin {
    @include swap_direction(margin, 0 0 14px 20px);
  }
  &.tooltip-right-margin {
    margin-#{$rdirection}: 15px;
  }
  &.tooltip-top-margin {
    margin-top: 15px;
  }
  &.tooltip-bottom-margin {
    margin-bottom: 15px;
  }
  &.tooltip-margin {
    @include swap_direction(margin, 15px 0);
  }
  &.text-right {
    text-align: $rdirection;
  }
  &.style-right {
    text-align: $rdirection;
    .tooltiptext-over {
      text-align: $ldirection;
      #{$ldirection}: auto;
      #{$rdirection}: 0;
      &::before {
        #{$ldirection}: auto;
        #{$rdirection}: 17%;
      }
    }
  }
  &.text-left {
    text-align: $ldirection;
  }
  .text-small {
    font-size: 10px;
  }
}

.site-footer {
  .footer {
    .site-footer-pc {
      @include breakpoint($medium-portrait-only) {
        @include swap_direction(padding, 20px);
      }
    }
  }
  &__bottom {
    &-links {
      @include breakpoint($landscape-up) {
        width: 77%;
      }
      @include breakpoint($extra-extra-large-up) {
        width: 70%;
      }
    }
  }
  &__accessibility {
    img,
    a {
      @include breakpoint($medium-up) {
        vertical-align: top;
      }
    }
  }
  &__terms,
  &__privacy {
    @include breakpoint($medium-up) {
      vertical-align: top;
    }
  }
  &__copyright {
    @include breakpoint($extra-extra-large-up) {
      width: 20%;
    }
    @include breakpoint($landscape-up) {
      width: 23%;
    }
  }
  .menu-ref {
    &:nth-child(3),
    &:nth-child(6) {
      @include swap_direction(margin, 0 auto);
      display: block;
      width: 87%;
    }
  }
  .livechat__text {
    margin-top: 5%;
    min-height: 42px;
  }
  .text-beauty,
  .chat-beauty,
  .footer-link {
    @include swap_direction(padding, 3% 0 0 5%);
    border: 1px solid $color-sand;
    border-radius: 5px;
    color: $color-sand;
    background: $color-darkest-green;
    display: inline-flex;
    width: 48%;
    height: 55px;
    float: $rdirection;
    font-size: 2.8vw;
    line-height: 16px;
    font-weight: normal;
    text-transform: uppercase;
    &__text {
      @include swap_direction(margin, 0 0 0 8%);
      display: inline-block;
    }
    &__icon {
      margin-top: 5px;
      vertical-align: top;
    }
  }
  .footer-link-container {
    margin-top: 10%;
    .footer-link {
      padding-#{$ldirection}: 10%;
      float: $ldirection;
      &.email-link {
        @include swap_direction(margin, 0 4% 0 0);
        @include swap_direction(padding, 10px 0 0 30px);
        background: url(/media/images/global/icon_mail.svg) no-repeat 6% 45%;
        background-size: 35px;
      }
      &.contact-link {
        @include swap_direction(padding, 16px 0 0 35px);
        background: url(/media/images/global/icon_phone.svg) no-repeat 10% 45%;
        background-size: 35px;
      }
      span {
        @include swap_direction(margin, 0 8%);
        display: inline-block;
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          .pr-multiselect {
            display: inline-block;
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-block-container {
        .pr-review-snapshot-block-cons {
          .pr-review-snapshot-snippets {
            .pr-snippet-stars-reco-stars {
              .pr-snippet-read-and-write {
                .pr-snippet-write-review-link {
                  #{$ldirection}: 0;
                  #{$rdirection}: 0;
                  bottom: 0;
                  width: 170px;
                  margin: 0 auto;
                  @include breakpoint($medium-up) {
                    width: 195px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#power_review_container {
  .write_a_review__container {
    .p-w-r {
      .pr-header-title {
        font-weight: 700;
        font-size: 18px;
      }
      .pr-submit {
        .pr-checkbox {
          input {
            top: 3px;
            #{$ldirection}: 3px;
          }
          &.pr-has-error {
            .pr-control-label {
              span {
                color: $color-red;
              }
            }
          }
        }
      }
    }
  }
}

select::-ms-expand {
  display: none;
}

.ff-results {
  &-share {
    &__cta {
      &-email {
        z-index: 1;
        top: 135px;
        @include breakpoint($medium-up) {
          top: 0;
        }
        &-sent {
          top: 110px;
          min-height: 150px;
          @include breakpoint($medium-up) {
            top: 0;
          }
        }
      }
    }
  }
}

.site-email-signup {
  &__fields {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &--email {
      order: 1;
      width: 100%;
    }
    .tooltip {
      order: 4;
    }
    &--sms {
      order: 2;
    }
    .site-promotions-signup__privacy-policy {
      order: 5;
    }
  }
  &__submit_btn {
    order: 6;
  }
  &__submit {
    order: 6;
  }
}

.site-sms-signup {
  &__terms-conditions {
    order: 3;
  }
}

.gnav-util {
  &__content--signup {
    .site-email-signup {
      &__submit_btn {
        margin: 0 auto;
      }
    }
  }
}

body {
  &.toolbar-drawer {
    padding-top: 0;
  }
}

.site-header {
  &__utility {
    padding-#{$ldirection}: 10px;
    .gnav-util {
      &__content {
        &--account {
          .social-login {
            .facebook-button {
              &.facebook-button-disabled {
                display: none;
                & + .fb-login-button {
                  z-index: 0;
                }
              }
            }
          }
          .tooltiptext-over {
            overflow-y: scroll;
            height: 150px;
            z-index: 2;
          }
        }
        &--cart {
          .cart-item {
            &__title {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  &.sticky {
    .site-header {
      &__utility-logo {
        @include breakpoint($landscape-up) {
          margin-#{$ldirection}: 0;
        }
      }
      &__menu {
        .menu__item {
          &.menu__item--category {
            &:nth-last-child(n + 8),
            &:nth-last-child(n + 8) ~ li.menu__item--category {
              @include breakpoint($medium-up) {
                margin: 0 6px 0;
              }
              @include breakpoint($landscape-up) {
                font-size: 9px;
              }
              @include breakpoint($extra-large-up) {
                font-size: 13px;
              }
              @media only screen and (min-width: 1360px) {
                font-size: 15px;
              }
            }
          }
          &--search {
            .icon--search {
              @include breakpoint($medium-up) {
                @include swap_direction(padding, 0 8px 0 20px);
              }
            }
          }
        }
      }
    }
  }
  &__menu {
    &__mobile-util-links {
      .LPMcontainer {
        float: $ldirection;
      }
    }
    .menu__item {
      &.menu__item--category {
        @include breakpoint($landscape-up) {
          font-size: 11px;
        }
        @include breakpoint($extra-large-up) {
          font-size: 15px;
        }
      }
      .site-header {
        &__subnav-button {
          a {
            width: 160px;
            line-height: 1.5;
            display: inline-block;
          }
        }
      }
    }
  }
  &__utility-item {
    &.site-header-utility-not-signed-in {
      width: 100px;
    }
  }
  &__utility-right {
    margin-#{$rdirection}: 20px;
    .gnav-util {
      &__icon {
        &__cart-count {
          position: absolute;
          top: 4px;
          width: 23px;
          @include breakpoint($landscape-up) {
            position: inherit;
            top: auto;
          }
        }
        .cart--bracket {
          display: none;
          @include breakpoint($landscape-up) {
            display: inline-block;
          }
        }
        .icon--bag {
          position: static;
          font-size: 14px;
          padding-#{$ldirection}: 0;
          width: 23px;
          height: 23px;
          @include breakpoint($landscape-up) {
            position: relative;
            font-size: 11px;
            padding-#{$ldirection}: 4px;
            width: 19px;
            height: 16px;
          }
        }
      }
    }
  }
}

.ff-quiz {
  &__inner {
    .ff-quiz {
      &__slide--1 {
        .ff-quiz {
          &__question {
            @include swap_direction(padding, 0);
            @include breakpoint($medium-up) {
              width: 85%;
            }
            li {
              @include swap_direction(margin, 0);
              width: 12%;
              @include breakpoint($medium-up) {
                @include swap_direction(margin, 0 0 0 2.5%);
                width: auto;
              }
            }
          }
          &__slider {
            @include breakpoint($medium-up) {
              width: 60%;
            }
          }
        }
      }
    }
  }
}

.store-locator-section {
  &__list-item--middle {
    width: 80%;
    @include breakpoint($medium-up) {
      width: 70%;
    }
  }
}

.spp__container,
.product-quickshop {
  .product-social {
    &__item--facebook {
      .icon--facebook {
        font-size: 18px;
        @include breakpoint($medium-up) {
          font-size: 22px;
        }
        &:hover {
          color: $color-white;
        }
      }
    }
  }
}

#cboxLoadedContent {
  @include swap_direction(padding, 0);
  .waitlist-form {
    width: 100%;
    margin: 0 auto;
    height: 350px;
    @include breakpoint($medium-up) {
      height: auto;
    }
    &__container {
      @include swap_direction(padding, 20px 10px);
      width: 100%;
    }
  }
}

.generic-helplinks-overlay,
.address-book-page__overlay {
  #cboxLoadedContent {
    padding: 30px 35px;
  }
}

.sidebar-page {
  &__content {
    width: auto;
  }
}

.product-flag {
  &__badge {
    &--nouveau {
      color: $color-white;
      background: $color-black;
    }
  }
}

.lp_survey_view_container {
  .lp_pages_area {
    .lp_radiobutton_wrapper {
      input[type='radio'] {
        display: none;
      }
    }
  }
}

.session-idle-popup {
  margin: 35px auto;
  &__content {
    text-align: center;
    &--header {
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    &--desc {
      margin: 0 5px 45px;
    }
  }
}

.login {
  &__container {
    .tooltip {
      display: none;
    }
  }
}
