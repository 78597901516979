.sign-in-page {
  .return-user {
    a {
      font-size: 15px;
      text-transform: inherit;
      text-decoration: none;
    }
  }
  .new-account {
    &__container {
      .required-text {
        text-align: left;
      }
    }
  }
  .return-user,
  .new-account {
    .form-item {
      &.password {
        position: relative;
        .eye-icon {
          cursor: pointer;
        }
      }
      &.show-password {
        .label {
          display: none;
        }
      }
    }
  }
}
