/**
 * Live Chat.
 */
#lpChat {
  input[type='text'],
  input[type='email'],
  textarea {
    &:placeholder {
      text-transform: none;
    }
  }
  select,
  option {
    text-transform: none;
  }
  .lp_buttons_area button {
    letter-spacing: 1px;
  }
  select.lp_select_field {
    -webkit-appearance: menulist;
    appearance: menulist;
  }
  .lp_survey_view_container {
    .lp_pages_area {
      .lp_radiobutton_wrapper {
        input[type='radio'] {
          display: none;
        }
      }
    }
  }
}

.regimen-module {
  .regimen-category {
    padding: 0 10px;
  }
}

.site-footer {
  .site-footer-pc {
    &__column {
      height: 216px !important;
    }
  }
}
