.review {
  .checkout {
    &__content {
      .checkout-panel {
        &--shipping {
          &.active.checkout-panel {
            border-bottom: none;
          }
          .shipping-address {
            position: relative;
            margin-bottom: 10px;
            border-bottom: none;
            .button--secondary {
              position: absolute;
              top: 10px;
              right: 0;
              margin-right: 0;
            }
          }
        }
        &__content {
          &--promotions,
          &--terms {
            padding-bottom: 10px;
            border-bottom: 1px solid $color-light-gray;
          }
        }
        .checkout-buttons-content {
          padding: 20px 0;
          overflow: auto;
        }
      }
    }
    &__sidebar {
      .checkout-panel {
        &--order-summary {
          border-top: none;
        }
      }
    }
  }
}
