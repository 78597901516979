.viewcart {
  .checkout {
    &__content {
      .checkout-panel {
        padding: 0 0 20px;
        border-bottom-width: 0;
      }
      .cross_sell_container {
        width: 100%;
        border-top: 1px solid $color-light-grey;
        .cart_cross_sell_item {
          float: left;
          width: 30%;
          text-align: center;
        }
        .cart_cross_sell_item:not(:last-child) {
          margin-right: 5%;
        }
      }
    }
  }
}

@media (max-width: 470px) {
  table.cart-items {
    thead {
      th.price {
        text-align: left;
      }
      th.qty {
        display: none;
      }
    }
    tbody {
      tr {
        td.price {
          display: block;
          padding-bottom: 15px;
        }
        td.qty {
          display: block;
          float: left;
          padding-top: 0;
        }
      }
    }
  }
}
