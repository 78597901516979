.confirm {
  .checkout {
    &__content {
      .checkout-panel {
        &__content {
          padding: 10px 0;
          &--confirmation {
            .order-number {
              width: 100%;
              padding: 15px;
              margin: auto auto 20px;
              text-align: center;
              font-size: 15px;
              font-weight: 700;
              background-color: $color-white-smoke;
              .link {
                font-size: 16px;
                color: $color-green;
                border-bottom: none;
              }
            }
          }
          &--registration {
            border-top: 1px solid $color-light-gray;
            .form-item {
              &.email-address {
                width: 100%;
                .email {
                  font-weight: 700;
                }
              }
            }
            input {
              &[type='submit'] {
                float: left;
                clear: both;
                @include breakpoint($med-small-down) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .checkout-buttons-content {
    padding: 20px 0;
    overflow: auto;
    &__container {
      float: left;
      text-align: left;
      input,
      a,
      & {
        @include breakpoint($med-small-down) {
          width: 100%;
        }
      }
    }
  }
}
