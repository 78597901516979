.waitlist-form {
  &__header {
    margin-top: 30px;
  }
  &__submit {
    margin-top: 15px;
  }
}

.section-chatprivacypolicy {
  .site {
    &-header,
    &-content__offer-banner,
    &-footer {
      display: none;
    }
  }
}

#pr-write {
  .p-w-r {
    .pr-header-required {
      display: block;
    }
  }
}
