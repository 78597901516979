.offer-banner-formatter {
  margin-bottom: 20px;
  border-top: none;
  background-color: $gray;
}

.checkout-buttons-content {
  .continue-checkout {
    @include breakpoint($medium-up) {
      width: auto;
    }
  }
}

section {
  .checkout {
    &__content {
      .checkout-panel {
        &__content {
          &--terms {
            label.error {
              &,
              a {
                color: $color-error;
              }
            }
          }
        }
        &--review,
        &--shipping {
          fieldset {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
    &__sidebar {
      &.empty {
        .checkout-panel {
          &--need-help {
            @include breakpoint($med-small-down) {
              border-top: 1px solid $color-light-gray;
            }
          }
        }
      }
      .checkout-panel {
        padding: 1em 0;
        &__header {
          margin: 0 0 0.5em 0;
        }
        &__heading {
          cursor: auto;
        }
        &--order-summary {
          @include breakpoint($med-small-down) {
            border-top: 1px solid $color-light-gray;
          }
        }
        &--offer-code {
          border-top: none;
          margin-top: 0;
        }
      }
      .order-summary-content {
        table {
          width: 100%;
        }
      }
    }
  }
}

.checkout-progress-bar {
  &__list-item {
    margin: 0;
    padding: 0;
    width: 25%;
  }
}

table {
  &.cart-items {
    width: 100%;
    thead {
      border-bottom: 1px solid $color-light-gray;
      th {
        padding: 0.8em 0;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    tbody {
      .cart-item-row-border {
        height: 1px;
        border-bottom: 1px solid $color-light-gray;
      }
      tr:not(.cart-item-row-border) {
        td {
          padding: 20px 0;
        }
      }
    }
    thead,
    tbody {
      th,
      td {
        .prod-name {
          text-decoration: none;
        }
        &.thumb,
        &.price,
        &.qty,
        &.total {
          width: 15%;
        }
        &.price,
        &.qty {
          text-align: center;
        }
        &.total {
          text-align: right;
          font-weight: bold;
        }
      }
    }
    tfoot {
      #subtotal {
        .price {
          text-align: right;
        }
      }
      td {
        padding: 20px 0;
      }
    }
  }
}

.review,
.payment {
  table {
    &.cart-items {
      border-bottom: 1px solid $color-light-gray;
    }
  }
}

.shipping,
.payment {
  .checkout-panel {
    &__content {
      &--payment-options {
        overflow: auto;
        .payment-type {
          @include breakpoint($medium-up) {
            float: left;
            margin-right: 25px;
          }
        }
      }
    }
  }
}

#max-quantity-container {
  padding: 30px 20px 20px;
  @include breakpoint($medium-up) {
    padding: 30px 35px;
  }
}
