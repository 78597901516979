.orders-page {
  .sidebar-menu {
    .orders {
      a {
        font-weight: 700;
      }
    }
  }
  &__content {
    @include breakpoint($landscape-down) {
      padding: 0px;
    }
    .no-orders {
      @include breakpoint($landscape-down) {
        padding: 0px 20px;
      }
    }
    .orders-info {
      @include breakpoint($landscape-down) {
        margin: 0px 20px;
        border-bottom: 1px solid $color-light-gray;
      }
    }
    .orders-list {
      @include breakpoint($landscape-down) {
        padding: 0px 20px;
      }
      &__table {
        &--order {
          &-carrier,
          &-date,
          &-number,
          &-status,
          &-tracking {
            @include breakpoint($landscape-up) {
              text-align: center;
              width: 20%;
            }
            span {
              width: 40%;
            }
          }
          &-number {
            a {
              pointer-events: auto;
              text-decoration: underline;
            }
          }
          @include breakpoint($landscape-up) {
            &-status {
              float: right;
            }
          }
        }
      }
    }
    .order-details {
      &-page__content {
        padding: 0;
        @include breakpoint($landscape-down) {
          padding: 0px 20px;
        }
        .order-details {
          margin: 0;
          @include breakpoint($landscape-down) {
            padding-bottom: 15px;
          }
        }
        .order-info {
          padding: 0;
          &__order_details {
            @include breakpoint($landscape-up) {
              padding-right: 15px;
            }
          }
          &__shipping_address {
            @include breakpoint($landscape-up) {
              padding-left: 5px;
              padding-right: 5px;
            }
            @include breakpoint($landscape-down) {
              padding: 20px 0px;
            }
          }
          &__billing_address {
            @include breakpoint($landscape-up) {
              padding-left: 15px;
            }
          }
        }
        .order-section__item {
          margin: 15px 0px;
          border-bottom: 1px solid $color-light-gray;
        }
      }
    }
  }
}
