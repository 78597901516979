.overview {
  .account-overview {
    &__profile-order {
      overflow: auto;
      .account-overview {
        &__section {
          @include breakpoint($medium-up) {
            float: left;
            width: 50%;
          }
          margin-left: 0;
          vertical-align: top;
          &.account-overview__address-book {
            width: 100%;
          }
        }
        &__address-book {
          .section-header {
            border-bottom: none;
          }
          .section-content {
            @include breakpoint($medium-up) {
              display: flex;
            }
            @include breakpoint($medium-down) {
              display: grid;
            }
            justify-content: space-around;
            align-items: flex-start;
          }
          .section-footer {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
