.click_and_collect_map {
  width: 100%;
  position: fixed;
  z-index: 110;
  top: 0;
  #{$ldirection}: 0;
  .overlay-content {
    height: 100%;
    width: 100%;
    position: fixed;
    .map-container {
      height: 100%;
      position: relative;
      overflow: hidden;
      background: $color-white;
      .address-map__location-panel {
        @include swap_direction(margin, 0);
        top: auto;
        #{$ldirection}: 0;
        bottom: 0;
        height: 60px;
        padding-bottom: 0;
        overflow: hidden;
        width: 100%;
        padding-#{$rdirection}: 0;
        display: block;
        position: absolute;
        z-index: 2;
        background-color: $color-white;
        @include breakpoint($medium-up) {
          width: 25em;
          height: auto;
        }
        @include breakpoint($medium-portrait-only) {
          width: 100%;
          height: auto;
        }
        .address-map__locations {
          height: 100%;
          overflow-y: scroll;
          background-color: $color-white;
          cursor: pointer;
          padding-top: 10px;
          .address-map__messages {
            @include swap_direction(border, 0);
            background-color: $color-white;
            margin-top: 30px;
          }
          .no-locations-found {
            margin-top: 60px;
          }
        }
        header {
          @include swap_direction(padding, 10px);
          position: absolute;
          width: 100%;
          z-index: 2;
          background: $color-white;
          height: 4em;
          h2 {
            color: $color-black;
            font-size: 2em;
            padding-#{$ldirection}: 0.5em;
          }
        }
        .address-map__location {
          @include swap_direction(padding, 1.5em 0.5em 1em);
          @include swap_direction(margin, 0);
          @include transition(all 0.5s);
          overflow: hidden;
          border-bottom: 1px solid $color-gray-light-apt;
          color: $color-black;
          .detail_reveal {
            display: none;
            &.selected {
              display: block;
            }
          }
          &:hover {
            @include swap_direction(padding, 2em 0.5em);
            background-color: $color--gray-lightest;
          }
          &.selected {
            @include swap_direction(padding, 2em 0.5em);
            background-color: $color--gray-lightest;
          }
          &:first-child {
            padding-top: 60px;
          }
          p {
            @include swap_direction(margin, 0 0 0.5em);
          }
          .image {
            @include swap_direction(margin, 0 1em 0 0);
            float: $ldirection;
            width: 100px;
            display: none;
            img {
              width: 100%;
            }
          }
          .address-map__distance {
            float: $rdirection;
            color: $color-gray-mdark;
            .uom {
              text-transform: lowercase;
            }
          }
          .location_description {
            font-style: italic;
          }
          .address-map__title {
            font-weight: bold;
            h2 {
              @include swap_direction(border, 0);
              @include swap_direction(padding, 0.5em 0);
              font-weight: bold;
              font-size: 14px;
              color: $color-black;
            }
          }
        }
        &.active {
          #{$ldirection}: 0;
          top: 0;
          height: auto;
          z-index: 3;
          a.close {
            span {
              @include icon('close', before);
            }
          }
        }
        .map {
          .address-map__location {
            min-width: 200px;
          }
        }
        &.hover {
          #{$ldirection}: 0;
        }
        a.close {
          @include swap_direction(margin, 0);
          display: block;
          position: absolute;
          #{$rdirection}: 35px;
          top: 16px;
          text-decoration: none;
          z-index: 3;
          background: none;
          width: 13px;
          height: auto;
          text-indent: 0;
          cursor: pointer;
          span {
            @include icon('close', before);
            display: block;
            font-size: 16px;
            color: $color-black;
            text-decoration: none;
          }
        }
      }
      .address-map__search-panel {
        @include swap_direction(padding, 0);
        #{$ldirection}: 0;
        width: 100%;
        font-size: 1.4em;
        position: absolute;
        z-index: 1;
        border-top: 0;
        background: $color-white;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 1em 0.5em 1em);
          #{$ldirection}: 25em;
          width: 28em;
        }
        @include breakpoint($medium-landscape-only) {
          #{$ldirection}: 18em;
        }
        @include breakpoint($medium-portrait-only) {
          #{$ldirection}: 4em;
        }
        .address-map__search-wrapper {
          width: 88%;
          background-color: $color-white;
          border-bottom: 1px $color-black solid;
          @include breakpoint($medium-up) {
            border-bottom: none;
          }
        }
        .address-map__search-box {
          overflow: hidden;
          width: 25em;
          &--field {
            @include swap_direction(border, 0);
            @include swap_direction(padding, 0);
            @include swap_direction(margin, 0 2%);
            @include swap_direction(border-radius, 0);
            width: 38%;
            height: 40px;
            font-size: 15px;
            float: $ldirection;
            text-align: center;
            @include breakpoint($medium-up) {
              @include swap_direction(border, 1px solid $color-lighter-gray);
              width: 65%;
              height: 40px;
            }
          }
          &--submit {
            @include button();
            min-width: 0;
          }
        }
        .status-bar-wrapper {
          @include swap_direction(margin, 0);
          width: 100%;
          position: fixed;
          bottom: 50px;
          #{$ldirection}: 0;
          text-align: center;
          @include breakpoint($medium-up) {
            width: 50%;
            bottom: 20px;
            #{$ldirection}: 36%;
          }
          .status-bar {
            .message {
              @include swap_direction(padding, 0.5em);
              @include swap_direction(border, 1px solid $color-gray-mdark);
              box-shadow: 0 1px 4px $color-medium-dark-gray;
              border-top: 0;
              &:first-child {
                @include swap_direction(border-radius, 5px 5px 0 0);
              }
              &:last-child {
                @include swap_direction(border-radius, 0 0 5px 5px);
              }
              .close {
                float: $rdirection;
                a {
                  text-decoration: none;
                  color: inherit;
                }
              }
              &.error {
                background: $color-light-red;
                color: $color-white;
              }
              &.warning {
                background: $color-salmon;
                color: $color-black;
              }
              &.success {
                background: $color-white;
                border-color: $color-black;
                color: $color-black;
              }
              &.information {
                background: $color-black;
                color: $color-white;
              }
            }
          }
        }
      }
      .address-map__directions-panel {
        width: 100%;
        top: 0;
        max-height: 100%;
        overflow-y: scroll;
        bottom: 0;
        display: block;
        position: absolute;
        z-index: 2;
        background-color: $color-white;
        #{$ldirection}: 0;
        @include breakpoint($medium-up) {
          width: 22em;
          height: auto;
          overflow-y: hidden;
        }
        header {
          height: 45px;
          padding-top: 5px;
          width: 100%;
          .button {
            @include swap_direction(border, 0);
          }
          h2 {
            color: $color-black;
            font-size: 2em;
            padding-#{$ldirection}: 0.5em;
          }
        }
        a.close {
          @include swap_direction(padding, 0);
          @include swap_direction(border, 0);
          display: block;
          position: absolute;
          z-index: 3;
          background: $white;
          #{$ldirection}: 90%;
          font-size: 2em;
          color: $color-black;
          min-width: 0;
          cursor: pointer;
          @include breakpoint($medium-up) {
            top: 0;
            background: none;
          }
        }
        .adp-substep,
        .adp-summary,
        .adp-text {
          color: $color-black;
          max-width: 200px;
        }
        .adp-text {
          width: 90%;
        }
        .adp-placemark {
          background-color: $color-white;
          width: 100%;
          td {
            @include swap_direction(padding, 3px);
            color: $color-black;
          }
        }
        .adp-directions,
        .adp-legal {
          width: 100%;
        }
        .address-map__directions {
          @include swap_direction(padding, 0 5%);
          background: $color-white;
          color: $color-black;
          overflow-y: scroll;
          overflow-x: hidden;
          max-height: 93%;
        }
      }
      .map {
        height: 100%;
        width: 100%;
        top: 0;
        .map-info-window {
          @include swap_direction(padding, 10px);
          background: $color-black;
          color: $color-white;
          max-width: 205px;
          .location {
            width: 190px;
            padding-#{$ldirection}: 0;
            .title {
              padding-bottom: 5px;
              span {
                @include swap_direction(margin, 0);
                color: $color-white;
                font-size: 14px;
                font-weight: bold;
              }
            }
            a.btn {
              @include swap_direction(padding, 5px);
              text-decoration: none;
            }
          }
        }
      }
      .map-side-tools {
        bottom: 15%;
        #{$rdirection}: 0.4em;
        position: absolute;
        .collection-tooltip {
          display: none;
        }
        .store-tooltip {
          display: none;
        }
        .search-box-toggle {
          @include swap_direction(padding, 6px);
          @include swap_direction(border-radius, 50%);
          width: 4em;
          height: 4em;
          position: relative;
          background-color: $color-white;
          color: $color-black;
          font-size: 14px;
          border: solid 1px;
          cursor: pointer;
          #{$rdirection}: 1.7em;
          z-index: 1;
        }
        .search-box-geolocate {
          @include swap_direction(margin, 15px 2px 0 0);
          @include swap_direction(border-radius, 50%);
          float: $ldirection;
          clear: none;
          display: block;
          position: relative;
          width: 1.7em;
          height: 1.7em;
          font-size: 32.9px;
          #{$rdirection}: 0.7em;
          z-index: 1;
          overflow: hidden;
          color: $color-white;
          text-align: center;
          text-overflow: ellipsis;
          background: $color-black;
          cursor: pointer;
          padding-top: 5px;
          font-family: icons;
        }
      }
      .search-box-cancel {
        @include swap_direction(margin, 0);
        text-align: center;
        cursor: pointer;
        position: absolute;
        padding-top: 0.1em;
        #{$rdirection}: 0;
        z-index: 1;
        width: 2em;
        height: 2em;
        font-size: 20px;
        background-color: $color-black;
        color: $color-white;
      }
    }
  }
  .close-container {
    display: none;
  }
  .store-collection-button,
  .local-collection-button {
    width: 206px;
    cursor: pointer;
    @include breakpoint($small-down) {
      width: 15%;
    }
  }
}
