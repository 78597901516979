.address-book {
  .sidebar-menu {
    .addressbook {
      a {
        font-weight: 700;
      }
    }
  }
  .section-header {
    position: relative;
  }
  .account-section__content {
    &--address-book-info {
      .account-section__container {
        @include breakpoint($medium-up) {
          display: flex;
          align-content: flex-start;
        }
        .column {
          @include breakpoint($medium-up) {
            width: 50%;
          }
          padding: 0px 10px;
          &.default {
            @include breakpoint($medium-down) {
              margin-top: 20px;
            }
          }
          .controls {
            a,
            span {
              margin: 8px 8px 3px 0px;
            }
            span {
              display: block;
            }
          }
        }
      }
    }
    &--address-book-other {
      margin-top: 20px;
      .account-section__container {
        .section-header {
          overflow: auto;
          padding: 0px 10px;
          &__header {
            float: left;
          }
          a {
            &.add-address {
              float: right;
              font-size: 12px;
              font-weight: 700;
              text-transform: uppercase;
              text-decoration: underline;
            }
          }
          .icon {
            float: right;
            margin-right: 4px;
            font-size: 9px;
            line-height: 22px;
          }
        }
        .column {
          padding: 10px;
          border-top: 1px solid $color-light-gray;
          .controls {
            text-align: right;
            span {
              display: block;
              margin: 10px 0px;
              text-align: left;
            }
          }
        }
      }
    }
    .account-section__container {
      overflow: hidden;
      margin: 20px;
      padding: 20px 10px;
      background: $color-dark-smoke;
      .section-header {
        margin: 0;
      }
      .column {
        .controls {
          a {
            font-size: 12px;
            text-decoration: underline;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .address-form {
    .field-container {
      overflow: auto;
      .form-item {
        @include breakpoint($medium-up) {
          float: left;
          width: 50%;
          padding-right: 20px;
        }
        input {
          width: 100%;
        }
        &.company_name {
          width: 100%;
        }
      }
    }
  }
  &-page__container--form {
    @include breakpoint($landscape-down) {
      padding: 0px 20px;
    }
  }
  &-page__overlay {
    padding: 30px;
    &_content {
      .button {
        min-width: 110px;
      }
    }
  }
}
