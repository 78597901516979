section {
  .checkout-panel {
    &__heading {
      font-size: 20px;
    }
    &__header {
      @include swap_direction(margin, 1em 0 0.5em 0);
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0.5em 0);
      }
    }
    &__expand {
      padding-top: 0;
    }
  }
  .checkout__content {
    .checkout-panel {
      @include swap_direction(padding, 0);
      overflow: unset;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0 0 1em 0);
      }
      &.checkout-panel {
        &--sign-in-main {
          @include breakpoint($medium-up) {
            padding: 1em;
          }
          .return-user-content {
            .social-login-section {
              margin-top: 20px;
            }
          }
        }
      }
      &--shipping,
      &--review {
        .select-address {
          border-bottom: none;
        }
        &-edit-address,
        .address {
          border-bottom: none;
          .address-form {
            &__name-fields {
              .first-name,
              .last-name {
                width: 100%;
                @include breakpoint($medium-up) {
                  width: 50%;
                }
              }
            }
            &__phones {
              width: 100%;
              margin-bottom: 10px;
              .phone-1 {
                margin-bottom: 20px;
                @include breakpoint($medium-up) {
                  padding-#{$rdirection}: 20px;
                  width: 50%;
                  float: $ldirection;
                }
                input {
                  width: 100%;
                }
              }
              .phone-2 {
                @include breakpoint($medium-up) {
                  width: 50%;
                  float: $rdirection;
                  padding-#{$rdirection}: 20px;
                }
                input {
                  width: 100%;
                }
              }
            }
          }
        }
        .shipping-address {
          &-display-content {
            .shipping-details {
              float: none;
              @include breakpoint($medium-up) {
                float: $ldirection;
              }
              .address_details {
                float: $ldirection;
                margin-bottom: 10px;
                width: 50%;
                text-transform: capitalize;
                .bold-text {
                  display: block;
                }
              }
            }
            .button--secondary {
              @include breakpoint($medium-up) {
                margin-top: 30px;
              }
            }
          }
        }
        .shipping-address,
        .view-address {
          .button--secondary {
            @include swap_direction(padding, 0);
            float: $rdirection;
            border: none;
            text-decoration: underline;
            text-align: $rdirection;
            color: $color-green;
            font-size: 16px;
            &:focus,
            &:hover,
            &:active {
              background-color: $color-white;
              outline: 0;
              @include breakpoint($medium-up) {
                background-color: $color-green;
              }
            }
            @include breakpoint($medium-up) {
              @include swap_direction(padding, 13px 15px 14px);
              text-decoration: none;
              text-align: center;
              border: 1px solid $color-lighter-gray;
              color: $color-dark-gray;
              float: $rdirection;
              margin-#{$rdirection}: 20px;
              font-size: 11px;
            }
          }
        }
        .checkout__subtitle {
          text-transform: uppercase;
          margin-top: 10px;
        }
        fieldset {
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 0);
          @include swap_direction(border, 0);
          width: 100%;
          .form-item {
            width: 100%;
            @include breakpoint($medium-up) {
              float: $ldirection;
              padding-#{$rdirection}: 20px;
              width: 50%;
            }
            input,
            select,
            textarea {
              width: 100%;
            }
            .button {
              margin-#{$ldirection}: 1px;
              width: 100%;
              cursor: pointer;
            }
            &.default-shipping {
              width: 100%;
              margin-bottom: 5px;
            }
            &.shipping-address {
              &-options {
                label {
                  float: $ldirection;
                }
              }
            }
            &.company_name {
              @include breakpoint($medium-up) {
                width: 100%;
              }
            }
            &.address3 {
              @include breakpoint($medium-up) {
                width: 100%;
              }
            }
          }
          .pc_email_promo_container {
            width: 100%;
            padding-bottom: 10px;
            @include breakpoint($medium-up) {
              padding-bottom: 20px;
            }
          }
        }
        .checkout-panel {
          @include swap_direction(padding, 0);
          &__header,
          &__heading,
          .field-are-required {
            @include breakpoint($medium-up) {
              font-size: 20px;
              margin-bottom: 0;
              display: block;
            }
            .icon {
              float: $rdirection;
            }
          }
        }
        .set-collection-point {
          margin-bottom: 15px;
          .local-collection-button {
            width: 100%;
            margin-top: 15px;
            @include breakpoint($medium-up) {
              width: 285px;
              margin-top: 0;
            }
          }
        }
        .show-collection-point {
          margin-bottom: 30px;
        }
        .gift-options {
          &-display-content {
            clear: both;
          }
        }
      }
      &--viewcart {
        .error {
          width: 100%;
          float: none;
        }
        .link--secondary {
          &.edit {
            display: none;
          }
        }
        .cart-items {
          &__header {
            &--products {
              width: 61%;
            }
          }
          &__item {
            border-bottom: 1px solid $color-lightest-grey;
            border-top: none;
            padding-bottom: 10px;
            @include breakpoint($medium-up) {
              border-bottom: none;
            }
            &.sample {
              @include breakpoint($medium-up) {
                border-top: 1px solid $color-lightest-grey;
              }
            }
            &:first-child {
              @include breakpoint($medium-up) {
                border-top: 1px solid $color-lightest-grey;
              }
            }
            &--thumb {
              margin-#{$rdirection}: 0;
              height: 140px;
              @include breakpoint($medium-up) {
                height: 120px;
              }
            }
            &--group-desc-remove {
              width: 55%;
              @include breakpoint($medium-up) {
                width: 45%;
                float: $ldirection;
                margin-#{$rdirection}: 0;
                @include breakpoint($medium-portrait-only) {
                  width: 37%;
                }
              }
            }
            &--group-replenish {
              width: 49%;
              float: $ldirection;
            }
            .cart-items {
              &__item--qty {
                width: auto;
                margin-top: 0;
                text-align: $ldirection;
              }
              &__item--total {
                @include breakpoint($medium-up) {
                  width: 25%;
                }
              }
            }
          }
        }
      }
      &--order-summary {
        text-align: $ldirection;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 1em);
          text-align: $ldirection;
        }
        .shipping-address {
          &-display-content {
            border-bottom: 1px solid $color-light-grey;
          }
        }
        .label {
          text-align: $ldirection;
          @include breakpoint($medium-up) {
            text-align: $ldirection;
            width: 35%;
            text-transform: none;
            padding-#{$rdirection}: 2%;
          }
        }
        .value {
          font-weight: normal;
          @include breakpoint($medium-up) {
            width: 15%;
            float: $ldirection;
          }
        }
        .total-container {
          border-top: 1px solid $color-light-grey;
          clear: both;
          @include breakpoint($medium-up) {
            border-top: none;
            clear: none;
          }
          &__content {
            .value {
              font-weight: bold;
            }
          }
        }
        .order-summary {
          &__shipping-content {
            width: 100%;
            clear: none;
            @include breakpoint($medium-up) {
              @include swap_direction(margin, 8% 0 0 64%);
              width: 36%;
            }
            .checkout-panel {
              &--shipmethod {
                padding-bottom: 10px;
              }
            }
          }
        }
        .substate-select {
          border-bottom: 1px solid $color-light-grey;
        }
        .checkout-panel {
          &__heading {
            width: 100%;
          }
        }
        .icon--plus {
          float: $rdirection;
          margin-top: 5px;
        }
      }
      &--shipmethod {
        text-align: $ldirection;
        padding-bottom: 20px;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 1em);
        }
        &.display {
          display: none;
        }
        @include breakpoint($medium-up) {
          text-align: $ldirection;
        }
        .ship-method-group-label {
          font-weight: bold;
          display: inline-block;
          width: 100%;
          margin-bottom: 10px;
          span {
            &:first-child {
              display: block;
              font-weight: bold;
              margin-bottom: 10px;
              @include breakpoint($medium-up) {
                display: inline-block;
                margin-#{$rdirection}: 10px;
              }
            }
            &:last-child {
              display: block;
              @include breakpoint($medium-up) {
                @include swap_direction(margin, 0);
                display: inline;
              }
            }
          }
          a.more-info {
            font-weight: normal;
            @include breakpoint($medium-up) {
              margin-#{$rdirection}: 0;
              margin-#{$ldirection}: 10px;
            }
            &.loading {
              background-image: url('/media/images/global/ajax-loading.gif');
            }
          }
        }
        .ship-method-groups {
          @include breakpoint($medium-up) {
            margin-bottom: 20px;
          }
        }
        .clear {
          display: block;
          padding-top: 5%;
          @include breakpoint($medium-up) {
            padding-top: 2%;
          }
        }
        select {
          @include breakpoint($medium-up) {
            width: 50%;
            font-size: 13px;
          }
        }
      }
      &--review {
        .payment-type {
          margin-bottom: 10px;
          label {
            display: none;
          }
          .radio {
            label {
              @include swap_direction(margin, 0.8em 0.8em 0.8em 0);
              display: inline-block;
            }
          }
        }
        .view-address {
          .button--secondary {
            float: $rdirection;
          }
          .select-address {
            clear: both;
            .saved-address {
              text-transform: capitalize;
              display: inline-block;
              width: 50%;
            }
          }
        }
        .checkout-panel {
          @include swap_direction(padding, 0);
          &__header,
          &__heading {
            margin-bottom: 0;
          }
        }
        .checkout__subtitle {
          text-transform: uppercase;
        }
      }
      .social-login {
        &__terms {
          text-align: $ldirection;
        }
        &__email-opt-in {
          text-align: $ldirection;
          padding-top: 0;
          padding-bottom: 0;
        }
        &__email-opt-in,
        &-buttons {
          margin-bottom: 20px;
        }
      }
      &--bottom-viewcart-buttons {
        @include breakpoint($medium-up) {
          border-bottom: none;
        }
      }
      &--sign-in-main {
        h3 {
          &.checkout-panel {
            &__heading {
              @include breakpoint($medium-up) {
                font-size: 20px;
              }
            }
          }
        }
        .new-account-content,
        .return-user-content {
          .sign-in-btn,
          .guest-btn {
            .account-signin {
              width: 100%;
              background-color: $color-white;
              color: $color-dark-gray;
              text-decoration: none;
              &:hover {
                background-color: $color-green;
              }
            }
          }
        }
      }
      &--new-account {
        .new-account-content {
          .email-promotions {
            input[type='checkbox'] ~ .field_label {
              background: $color-white;
              padding-#{$ldirection}: 0;
            }
          }
        }
      }
      .icon--plus.icon--minus {
        @include icon('minus');
        margin-top: 0px;
      }
      .order-summary {
        &__tax-value {
          font-weight: normal;
        }
      }
    }
  }
  .checkout__sidebar {
    border-top: none;
    .checkout-panel {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      @include breakpoint($medium-up) {
        padding: 1em 0;
        margin: 0 1em;
      }
      &--viewcart {
        h2 {
          width: 100%;
        }
      }
      &--order-summary {
        text-align: $ldirection;
        .value {
          font-weight: normal;
        }
        .total-container {
          &__content {
            .value {
              font-weight: bold;
            }
          }
        }
      }
      &--shipmethod {
        text-align: $ldirection;
        .ship-method-group {
          width: 100%;
          select {
            width: 100%;
          }
        }
      }
      &--offer-code {
        @include breakpoint($medium-up) {
          border-top: 1px solid $color-light-grey;
          margin-top: 10px;
        }
      }
    }
  }
  .gift-options-content {
    @include swap_direction(padding, 0);
    margin-bottom: 0;
    @include breakpoint($medium-up) {
      padding: 8px 0 16px 0;
    }
    .checkout-panel__content {
      margin-bottom: 0 !important;
      .gift-options-content__container {
        .gift-message__container {
          .card-messages {
            margin-bottom: 0;
            .card-message-to {
              padding-#{$rdirection}: 4px;
            }
          }
          .giftmessage_count {
            @include breakpoint($medium-up) {
              width: 50%;
            }
          }
          .gift-message__title {
            text-transform: uppercase;
          }
        }
      }
    }
    &--header {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 98%;
      }
      .icon {
        position: relative;
        top: 0;
        #{$rdirection}: 0;
      }
    }
  }
}

.checkout--confirmation-page {
  .social-login-section {
    .social-login {
      .text-left {
        @include breakpoint($medium-up) {
          margin-#{$ldirection}: 28%;
          width: 47%;
          display: block;
        }
      }
      &__terms {
        min-width: auto;
        @include breakpoint($medium-up) {
          min-width: 330px;
        }
      }
    }
  }
  .checkout-panel {
    &__header,
    &__heading {
      margin-bottom: 0;
    }
    &__content {
      &.order-summary-content {
        border-bottom: 1px solid $color-lightest-grey;
      }
      .form-item {
        margin-bottom: 20px;
        @include breakpoint($medium-up) {
          margin-bottom: 0;
        }
        &.sticky-checkout-button {
          margin: 0;
        }
      }
    }
  }
  .confirmation-content {
    .order-number {
      a {
        text-decoration: none;
      }
    }
  }
}

#viewcart {
  section {
    .checkout-panel {
      &--viewcart {
        .cart-items {
          &__item {
            &--group-desc-remove {
              width: 100%;
              @include breakpoint($medium-up) {
                width: 45%;
              }
              @include breakpoint($medium-portrait-only) {
                width: 100%;
              }
              .desc {
                width: 80%;
              }
            }
            &--price {
              width: 30%;
              @include breakpoint($medium-up) {
                clear: none;
                width: 20%;
              }
            }
            &--qty {
              @include breakpoint($landscape-up) {
                width: 13%;
                text-align: center;
              }
            }
            &--total {
              @include breakpoint($landscape-up) {
                width: 11%;
              }
            }
          }
        }
      }
      &--offer-code {
        @include breakpoint($medium-up) {
          border-top: none;
          margin-top: 0;
        }
      }
      &--shipmethod {
        .ship-method-groups {
          display: none;
          @include breakpoint($medium-up) {
            display: block;
          }
        }
      }
    }
    .shipping__delivery {
      &-address {
        display: none;
      }
    }
  }
}
/* payment page MCO style */
.active-panel {
  &-review {
    section {
      &.checkout-single-page {
        @include swap_direction(padding, 0 2em 2em 2em);
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 50px 15px 15px 15px);
        }
        .tooltip-margin {
          display: none;
        }
      }
      .shipping-panel {
        &__title {
          display: none;
        }
      }
      .checkout {
        &__header {
          display: none;
        }
        &__content {
          .checkout-panel {
            @include swap_direction(padding, 0);
            overflow: auto;
            &--links {
              border-bottom: 1px solid $color-lighter-gray;
            }
            &.order-summary-content {
              padding-bottom: 10px;
              .total-container {
                padding-top: 1em;
              }
            }
          }
        }
        &-panel {
          &--sign-in-main,
          &--registration {
            display: none;
          }
          .payment-type-field {
            @include swap_direction(padding, 0 0 15px 0);
            .checkout-panel {
              &__header {
                text-align: center;
              }
            }
            .checkout__subtitle,
            .error ::after {
              @include swap_direction(padding, 10px 0);
              border-bottom: 1px solid $color-dark-gray;
            }
            .checkout__subtitle {
              border-bottom: 1px solid $color-lighter-gray;
              @include breakpoint($medium-up) {
                border-bottom: none;
                font-size: 20px;
              }
            }
            .payment-type {
              @include swap_direction(margin, 15px 0);
              position: relative;
              border-top: 1px solid $color-dark-gray;
              @include breakpoint($medium-up) {
                @include swap_direction(margin, 15px 0 0 0);
                border-top: none;
              }
              label:first-child {
                display: none;
              }
              .radio {
                @include swap_direction(padding, 15px 0);
                display: block;
                @include breakpoint($medium-up) {
                  @include swap_direction(padding, 25px 0);
                }
                img {
                  position: absolute;
                  top: 15px;
                  #{$rdirection}: 0;
                  @include breakpoint($medium-up) {
                    #{$ldirection}: 190px;
                    top: auto;
                  }
                }
                input[type='radio'] ~ label {
                  &::before {
                    @include swap_direction(margin, 0 16px 0 10px);
                  }
                }
              }
              .group2 {
                .radio {
                  border-top: 1px solid $color-lighter-gray;
                  border-bottom: 1px solid $color-dark-gray;
                  @include breakpoint($medium-up) {
                    border-bottom: none;
                  }
                  img {
                    top: 68px;
                    @include breakpoint($medium-up) {
                      #{$ldirection}: 150px;
                      top: auto;
                    }
                  }
                }
              }
            }
            .view-address {
              @include breakpoint($medium-up) {
                border-top: 1px solid $color-lighter-gray;
                position: relative;
              }
              .button--secondary {
                position: absolute;
                #{$rdirection}: 0;
                top: 30px;
              }
            }
            .checkout-order {
              &__terms {
                span {
                  width: 86%;
                  float: $ldirection;
                  display: block;
                  @include breakpoint($medium-up) {
                    width: 92%;
                    margin-top: 15px;
                    margin-bottom: 60px;
                  }
                }
                &::before {
                  @include swap_direction(margin, 4px 16px 0 10px);
                  float: $ldirection;
                  @include breakpoint($medium-up) {
                    margin-top: 20px;
                  }
                }
              }
            }
            #continue-btn {
              @include breakpoint($medium-up) {
                @include swap_direction(margin, 10px 0 20px);
                overflow: auto;
                float: $rdirection;
                .form-submit {
                  @include swap_direction(margin, 0 20px 0 0);
                }
              }
            }
          }
          &--shipping {
            &.active {
              &.checkout-panel {
                border-bottom: 1px solid $color-lighter-gray;
              }
            }
            @include breakpoint($medium-up) {
              border-bottom: none;
            }
            .icon--plus {
              float: $rdirection;
            }
            .checkout-panel {
              &__heading {
                display: block;
              }
              &--viewcart {
                h2 {
                  display: inline-block;
                  margin-bottom: 0;
                  span {
                    margin-#{$ldirection}: 10px;
                  }
                }
              }
            }
            .checkout__subtitle {
              @include swap_direction(margin, 1em 0 0.5em 0);
            }
            .shipping-address {
              border-bottom: 1px solid $color-light-gray;
              border-top: 1px solid $color-light-gray;
              @include breakpoint($landscape-up) {
                border-bottom: none;
                margin-bottom: 10px;
              }
            }
            .address {
              border-bottom: 1px solid $color-lighter-gray;
            }
            .shipping-edit {
              &__review {
                float: $ldirection;
                width: 60%;
                margin-bottom: 15px;
              }
            }
            .button--secondary {
              @include swap_direction(padding, 0);
              color: $color-green;
              text-decoration: underline;
              font-size: 16px;
              border: none;
              float: $rdirection;
              text-align: $rdirection;
            }
          }
          &--registration {
            .checkout-panel {
              &__heading {
                margin-bottom: 0;
              }
            }
          }
          &__expand {
            padding-top: 0;
          }
        }
      }
    }
    .btn-mini {
      @include swap_direction(margin, 10px 0 20px 0);
      &[disabled] {
        opacity: 0.2;
      }
    }
  }
  &-shipping {
    section {
      &.checkout-single-page {
        @include swap_direction(padding, 0 2em 0 2em);
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 50px 15px 15px 15px);
        }
        .tooltip-margin {
          text-align: $ldirection;
        }
        .sticky-checkout-button {
          @include breakpoint($medium-up) {
            box-shadow: none;
            position: initial;
            margin-top: 15px;
            padding: 0 20px 0 0;
            .button {
              height: 38px;
              font-size: 11px;
            }
          }
        }
      }
      .checkout__content {
        .checkout__panel-title {
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 20px;
          font-weight: bold;
          font-size: 24px;
        }
        .checkout-panel {
          &--shipmethod {
            padding-bottom: 0;
            &.display {
              display: block;
              @include breakpoint($medium-up) {
                text-align: $ldirection;
                border-top: 1px solid $color-lighter-gray;
                border-bottom: 1px solid $color-lighter-gray;
              }
              .checkout-panel {
                &__header {
                  @include breakpoint($medium-up) {
                    padding: 0.5em 0;
                  }
                }
              }
            }
          }
          &--shipping {
            .billing_address_section {
              .new-address {
                padding-bottom: 10px;
                margin-bottom: 0;
              }
            }
            .sub-section {
              margin-bottom: 10px;
              &.new-address-container {
                border-bottom: none;
                padding-bottom: 0;
                .address-form {
                  &__bill-to-shipping {
                    margin-bottom: 0;
                  }
                }
              }
              &.new-address {
                @include breakpoint($medium-up) {
                  padding-bottom: 15px;
                }
                &.new-address-container {
                  @include breakpoint($medium-up) {
                    padding-bottom: 0;
                  }
                }
                h3 {
                  @include breakpoint($medium-up) {
                    padding-top: 15px;
                  }
                }
              }
            }
            &-edit-address,
            .new-address {
              .checkout__subtitle {
                padding-bottom: 10px;
                @include breakpoint($medium-up) {
                  font-size: 20px;
                }
              }
            }
            &-edit-address {
              @include breakpoint($medium-up) {
                border-bottom: 1px solid $color-lighter-gray;
                padding-bottom: 20px;
              }
              &.billing-address-from-fields {
                @include breakpoint($medium-up) {
                  border-bottom: none;
                  padding-top: 20px;
                  padding-bottom: 0;
                }
              }
              .form-item {
                &.select-menu {
                  padding-right: 20px;
                  select[name='SELECTED_ADDRESS_ID'] {
                    width: 100%;
                  }
                }
              }
            }
            .email-and-sms-promotions {
              section {
                .checkout-panel {
                  &__heading {
                    @include breakpoint($medium-up) {
                      padding-top: 1em;
                    }
                  }
                }
              }
            }
          }
          &--viewcart {
            .checkout-panel {
              &__heading {
                display: inline-block;
                span {
                  margin-#{$ldirection}: 10px;
                }
              }
            }
          }
          &--links {
            border-top: 1px solid $color-lighter-gray;
            border-bottom: 1px solid $color-lighter-gray;
            &.promotions-content {
              border-top: 1px solid $color-lighter-gray;
              border-bottom: none;
            }
          }
          .ship_group_0 {
            display: none;
          }
          .local-collection {
            .checkout__panel {
              &--heading {
                text-transform: uppercase;
                .local-collection-link {
                  float: $rdirection;
                  border: none;
                  color: $color-green;
                  font-size: 16px;
                  margin-#{$rdirection}: 5px;
                  font-family: $font--neue-haas-unica-pro;
                  letter-spacing: 1px;
                }
              }
            }
          }
          .field-are-required {
            display: none;
          }
          .new-address {
            .label-content {
              font-weight: bold;
            }
          }
          &__content {
            .gift-options-content {
              margin-bottom: 0;
              border-bottom: 0;
              @include breakpoint($medium-up) {
                border-top: none;
                border-bottom: 1px solid $color-lighter-gray;
              }
            }
          }
          &--order-summary {
            border-top: 1px solid $color-lighter-gray;
            .order-summary-content {
              border-bottom: 1px solid $color-lighter-gray;
            }
          }
        }
        .shipping {
          &__delivery-address {
            font-size: 15px;
            text-transform: uppercase;
          }
          &-edit-address-content {
            &__title {
              @include swap_direction(padding, 1.5em 0);
              border-top: 1px solid $color-lighter-gray;
              border-bottom: 1px solid $color-lighter-gray;
              @include breakpoint($medium-up) {
                @include swap_direction(padding, 1em 0 0 0);
                border: none;
                font-size: 20px;
              }
            }
          }
        }
      }
      .tooltip {
        a.link {
          text-decoration: none;
          border-bottom: none;
        }
      }
      .checkout {
        &__sidebar {
          .checkout-panel {
            &--shipmethod {
              &.display {
                @include breakpoint($medium-up) {
                  display: none;
                }
              }
            }
            &--offer-code {
              @include breakpoint($medium-up) {
                border-top: none;
              }
            }
          }
        }
      }
    }
  }
}

.active-panel-registration {
  .checkout {
    &--confirmation-page {
      @include swap_direction(padding, 0 2em 0 2em);
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 50px 15px 15px 15px);
      }
    }
  }
  .checkout-panel {
    &--confirmation {
      .print-buttons {
        float: none;
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 0 auto);
          width: 50%;
        }
      }
      .checkout-panel {
        &__heading {
          line-height: 20px;
          font-size: 17px;
          @include breakpoint($medium-up) {
            font-size: 20px;
          }
        }
      }
      .confirmation-content {
        margin-top: 20px;
        &__email-coming {
          @include breakpoint($medium-up) {
            text-align: center;
          }
        }
      }
      .order-number {
        background-color: $color-white;
        font-size: 19px;
        @include breakpoint($medium-up) {
          background-color: $color-white-smoke;
          font-size: 15px;
        }
        .link {
          color: $color-black;
          font-size: 18px;
          @include breakpoint($medium-up) {
            font-size: 16px;
            color: $color-green;
          }
        }
      }
    }
    &--registration {
      .registration_header {
        border-bottom: none;
        @include breakpoint($medium-up) {
          border-bottom: 1px solid $color-light-gray;
        }
        .checkout-panel {
          &__heading {
            text-align: center;
            font-size: 20px;
            text-transform: uppercase;
            width: 100%;
            @include breakpoint($medium-up) {
              text-align: $ldirection;
              width: 100%;
            }
          }
        }
      }
      .registration-content {
        &__heading {
          font-size: 20px;
          text-transform: uppercase;
          @include breakpoint($medium-up) {
            font-size: 15px;
            text-transform: lowercase;
          }
        }
        &__create-account {
          width: 100%;
        }
        .checkout-registration {
          @include breakpoint($medium-up) {
            @include swap_direction(margin, 20px 0 0 0);
          }
          &__email-address {
            margin-bottom: 10px;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            @include breakpoint($medium-up) {
              font-weight: normal;
              text-transform: lowercase;
              font-size: 15px;
            }
            .example-user-email {
              text-transform: lowercase;
              font-size: 16px;
            }
          }
          &__password {
            .password {
              label {
                display: none;
                @include breakpoint($medium-up) {
                  display: inline-block;
                }
              }
            }
            .toggle-password {
              position: absolute;
              top: 12px;
              #{$rdirection}: 35px;
              border-bottom: 1px solid $color-light-gray;
            }
          }
        }
      }
    }
  }
  .checkout__sidebar {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .checkout--confirmation-page {
    .order-heading {
      font-size: 24px;
    }
  }
  .site-footer {
    .footer {
      .site-email-signup,
      .site-footer__bottom,
      .social-media-links {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
      .menu-ref {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          display: none;
          @include breakpoint($medium-up) {
            display: block;
          }
        }
        &:last-child {
          margin: 0 auto;
          display: block;
          width: 87%;
        }
      }
      .site-footer {
        &__bottom-condensed {
          display: block;
        }
      }
    }
  }
}

.active-panel-cart {
  section {
    &.viewcart {
      @include swap_direction(padding, 2em 2em 0 2em);
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 50px 15px 0 15px);
      }
    }
    .checkout-panel {
      &--promo {
        .messages {
          @include swap_direction(padding, 0);
          @include swap_direction(margin, 0);
        }
        .offer-banner-formatter {
          border-top: 1px solid $color-lighter-gray;
          @include breakpoint($medium-up) {
            border-top: none;
          }
        }
      }
      &--viewcart {
        border-bottom: none;
        @include breakpoint($medium-up) {
          border-bottom: 1px solid $color-light-gray;
        }
        .edit-link {
          display: none;
        }
        .messages {
          @include swap_direction(padding, 0);
          @include swap_direction(margin, 0);
        }
      }
      &__content {
        padding-bottom: 10px;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0);
        }
      }
      .total-container {
        &__content {
          padding-top: 10px;
        }
      }
    }
  }
  .checkout__sidebar {
    @include swap_direction(margin, 0 2em 1em 2em);
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 0);
      border-bottom: none;
    }
    .checkout-panel {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0);
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 1em 0);
        @include swap_direction(margin, 0 1em);
      }
      &--links {
        border-bottom: 1px solid $color-light-gray;
        &:last-child {
          @include breakpoint($medium-up) {
            border-bottom: none;
          }
        }
      }
    }
  }
  .footer {
    .site-email-signup,
    .site-footer__bottom,
    .social-media-links {
      display: none;
    }
    .menu-ref {
      &:nth-child(1),
      &:nth-child(2) {
        display: none;
      }
    }
    .site-footer {
      &__bottom-condensed {
        display: block;
      }
      &-pc {
        display: none;
      }
    }
  }
}

.active-panel-signin {
  .shipping-panel {
    &__title {
      display: none;
    }
  }
  .checkout-single-page {
    @include swap_direction(padding, 0 2em 0 2em);
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 50px 15px 15px 15px);
    }
  }
  .checkout-panel {
    &--links {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    .social-login-section {
      .social-login {
        &__terms {
          border-bottom: none;
        }
        .tooltip {
          margin-top: 5px;
        }
      }
    }
  }
  .tooltip-margin {
    display: none;
  }
}

.active-panel-samples {
  .site-container {
    margin-top: 20px;
    .samples-page {
      @include swap_direction(padding, 1em 2em 0 2em);
      border-top: 1px solid $color-light-gray;
      @include breakpoint($medium-up) {
        padding-top: 50px;
        border-top: none;
      }
      .remaining {
        p {
          font-size: 16px;
          @include breakpoint($medium-up) {
            font-size: 15px;
          }
        }
      }
      .sticky-checkout-button {
        @include breakpoint($medium-up) {
          box-shadow: none;
          position: initial;
          padding: 0;
          width: auto;
          .button {
            height: 38px;
            font-size: 11px;
          }
        }
      }
    }
  }
}

.checkout-progress-bar {
  &__list-item {
    @include swap_direction(margin, 0 0 0 7%);
    padding: 0;
    width: 24%;
    font-size: 14px;
    @include breakpoint($medium-up) {
      margin: 0 20px;
      padding: 0 20px;
      width: auto;
      font-size: 15px;
    }
    &--shipping {
      margin-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 20px;
      }
    }
    &--payment {
      margin-#{$rdirection}: 0;
      width: 34%;
      @include breakpoint($medium-up) {
        margin-#{$rdirection}: 20px;
        width: auto;
      }
    }
  }
}

#cboxClose {
  @include breakpoint($medium-up) {
    #{$rdirection}: 15px;
  }
}

.bold-text {
  font-weight: bold;
}

.address-options {
  &__address-container {
    display: inline-block;
  }
}

.checkout-index {
  .ship-method {
    &-group-label {
      .home-delivery {
        &__option {
          float: $ldirection;
          label {
            background: url(/media/images/global/home_icon.svg) no-repeat center 15%;
          }
          input[type='radio']:checked + label {
            background: url(/media/images/global/home_icon_selected.svg) no-repeat center 15%;
          }
          label,
          input[type='radio']:checked + label {
            background-size: 40%;
          }
        }
      }
      .click-and-collect {
        &__option {
          float: $rdirection;
          label {
            background: url(/media/images/global/location_icon.svg) no-repeat center 22%;
          }
          input[type='radio']:checked + label {
            background: url(/media/images/global/location_icon_selected.svg) no-repeat center 22%;
          }
          label,
          input[type='radio']:checked + label {
            background-size: 31%;
          }
        }
      }
      .shipment-option {
        display: block;
        @include breakpoint($medium-up) {
          display: inline;
        }
      }
      .home-delivery,
      .click-and-collect {
        &__option {
          width: 45%;
          label {
            @include swap_direction(padding, 44% 0 10% 0);
            display: block;
            width: 100%;
            font-size: 0.7em;
            border: 1px solid $color-medium-gray;
            text-align: center;
            text-transform: uppercase;
            height: 90px;
            border-radius: 5px;
            &:before {
              @include swap_direction(margin, 0);
              visibility: hidden;
              width: 0;
            }
            a {
              display: none;
            }
          }
          input[type='radio']:checked + label {
            border: 1px solid $color-green;
            color: $color-green;
          }
        }
      }
      .shipping__delivery {
        &-address {
          float: $ldirection;
          width: 100%;
          margin-top: 10px;
          font-size: 20px;
        }
      }
    }
    &-home-group {
      .checkout {
        &__panel-header {
          @include breakpoint($medium-up) {
            display: none;
          }
        }
      }
      select {
        @include swap_direction(margin, 10px 0);
      }
      label {
        display: inline-block;
      }
    }
  }
  .checkout-panel {
    &--shipping-edit-address {
      padding-bottom: 0;
    }
    &--order-summary {
      .toggle-icon {
        float: $rdirection;
        margin-top: 5px;
      }
      .checkout-panel {
        @include breakpoint($medium-up) {
          overflow: initial;
        }
      }
    }
    &--shipmethod {
      border-bottom: none;
    }
    .checkout-email {
      @include swap_direction(margin, 10px 0);
      border-top: 1px solid $color-gray-light-apt;
      background-color: $color-gray-lightest-apt;
      padding-#{$ldirection}: 20px;
      line-height: 50px;
    }
    .new-address {
      margin-bottom: 0;
      div {
        &:first-child {
          @include breakpoint($medium-up) {
            margin-bottom: 10px;
          }
        }
        .company_name {
          margin-bottom: 20px;
        }
      }
      .billing-address-display {
        @include swap_direction(margin, 15px 0 10px 0);
        float: $ldirection;
        width: 50%;
        text-transform: capitalize;
      }
      .bill-display-edit {
        float: $rdirection;
        margin-#{$rdirection}: 0;
        margin-top: 10px;
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 20px;
        }
        a {
          @include swap_direction(padding, 10px 0);
          border: none;
          color: $color-green;
          text-decoration: underline;
          min-width: auto;
          font-size: 16px;
          margin-#{$rdirection}: 5px;
          @include breakpoint($medium-up) {
            font-size: 11px;
            min-width: 140px;
            border: 1px solid $color-lighter-gray;
            text-decoration: none;
            color: $color-black;
          }
          &:hover {
            color: $color-black;
          }
        }
      }
    }
    .address-options {
      border-top: 1px solid $color-gray-light-apt;
      margin-bottom: 0;
      .edit-address-link {
        float: $rdirection;
        a {
          color: $color-green;
          border-bottom: 1px solid $color-green;
        }
      }
      .button {
        display: none;
      }
      &__address-container {
        height: auto;
        width: 50%;
        .co_default {
          font-weight: bold;
          color: $color-green;
        }
      }
    }
    .add_new_address {
      border-top: 1px solid $color-gray-light-apt;
      padding-top: 20px;
      span.new_shipping_address {
        text-transform: uppercase;
        padding-bottom: 10px;
        display: inline-block;
      }
    }
    &--viewcart {
      .checkout-panel {
        &__header {
          display: inline-block;
        }
      }
    }
  }
}

.generic-helplinks-overlay {
  .customer-service_anchor {
    display: block;
  }
}

#colorbox {
  .customer-service {
    &__content {
      padding: 0 20px;
    }
  }
  .max_policy {
    padding: 30px 20px 20px 20px;
  }
}
