.account-section {
  &__nav {
    .sidebar-menu {
      li {
        @include breakpoint($landscape-down) {
          border-bottom: 1px solid $color-light-gray;
        }
      }
      &__item__link {
        top: 35%;
      }
    }
  }
  &__welcome-user {
    word-wrap: break-word;
  }
  .section-header {
    @include breakpoint($landscape-down) {
      margin: 0px 20px;
    }
    .account-section__header {
      margin: 10px 0px 30px;
    }
    .section-header__header {
      margin-bottom: 10px;
      font-family: $font--text;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .section-info__header {
    clear: both;
    padding: 20px 0px 10px;
    text-transform: uppercase;
  }
  .error_messages {
    color: $color-error;
  }
}
