.order-details-page {
  &__content {
    .order-products {
      .cart-item {
        &__qty {
          @include breakpoint($medium-up) {
            width: 37%;
          }
        }
      }
    }
  }
  .order-details {
    .order-number,
    .order_id {
      a {
        pointer-events: none;
        text-decoration: none;
      }
    }
  }
}

.order-details-page__content {
  .order-shipmethod {
    margin-bottom: 20px;
  }
  .order-info__item {
    .payment-method-name {
      display: inline;
    }
  }
}

.address-book-page {
  &__overlay {
    .address-form {
      select,
      input {
        padding: 0 5px;
      }
      .form-item,
      select {
        @include breakpoint($medium-up) {
          width: 100%;
        }
      }
      .first_name-field {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 48%;
          padding-#{$rdirection}: 8px;
          float: $ldirection;
        }
      }
      .last_name-field,
      .city-field {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 48%;
          float: $rdirection;
        }
        .city-label {
          &__txt {
            margin-top: 7px;
          }
        }
        .city-results {
          p {
            padding-#{$ldirection}: 15px;
            margin-top: calc(-3em - -1px);
            width: 40%;
            float: $rdirection;
            @include breakpoint($medium-up) {
              margin-#{$ldirection}: 10px;
              padding-#{$ldirection}: 0;
              float: $ldirection;
              width: 220px;
            }
          }
        }
      }
      .phone-label {
        &__container {
          margin-bottom: 10px;
        }
      }
      .form-field {
        &.postalcode {
          &_state-field {
            input[type='text'] {
              float: none;
            }
          }
        }
      }
    }
    .buttons {
      .address-overlay_submit,
      .button--secondary {
        width: 38%;
      }
    }
  }
}

.sign-in-page {
  .new-account {
    &__fieldset {
      .form-item {
        .privacy-form-container {
          label {
            display: block;
            line-height: 13px;
            font-size: 10px;
            text-align: $ldirection;
            margin-top: 6px;
          }
        }
      }
    }
  }
  .new-account,
  .return-user {
    &.fb_login_disabled {
      @include breakpoint($medium-up) {
        width: 390px;
        margin: 0 auto;
      }
    }
  }
}

.social-login-section {
  .social-login {
    .facebook-logo {
      #{$ldirection}: 10px;
      #{$rdirection}: auto;
    }
    .facebook-button {
      &.facebook-button-disabled {
        position: relative;
        background-color: $color-medium-gray;
        pointer-events: none;
        z-index: 1;
      }
    }
  }
}

.social-info {
  &__header {
    float: $ldirection;
    width: 100%;
  }
  &__content {
    p {
      float: $ldirection;
      width: 100%;
    }
  }
}

.account-settings {
  &__container {
    h3 {
      width: 80%;
    }
  }
}

.password-sent-page {
  .sent-info {
    &__sign-in-back {
      .back-icon {
        text-decoration: none;
      }
    }
    &__email {
      height: auto;
    }
  }
}

.password-reset-page {
  .password-reset {
    &__fieldset {
      .email-address {
        height: auto;
        &__email {
          display: inline-block;
          margin-bottom: 0;
        }
      }
    }
  }
}

.account-orders-list {
  .orders-list {
    &__table {
      &--order-accordion {
        float: $rdirection;
      }
    }
  }
}
