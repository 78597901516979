#confirm {
  .change-password {
    .password-field {
      &__info {
        @include breakpoint($medium-up) {
          &-checkout {
            #{$ldirection}: 220px;
            top: -80px;
          }
        }
      }
    }
  }
}

.change-password {
  .profile__change-password-overlay {
    .profile__reset-password {
      .error_messages {
        margin-#{$ldirection}: 22px;
      }
      .profile-info {
        &__item {
          @include swap_direction(margin, 15px 17px);
          @include breakpoint($medium-up) {
            @include swap_direction(margin, 15px 20px);
          }
          input {
            width: 80%;
            @include breakpoint($medium-up) {
              width: 85%;
            }
          }
          .form-cancel {
            width: 40px;
            @include breakpoint($medium-up) {
              float: #{$ldirection};
              width: 40px;
              margin-#{$rdirection}: 7%;
            }
          }
        }
        .button {
          width: 47%;
        }
        &__meter {
          &-wrapper {
            @include swap_direction(margin, 15px 22px);
            @include breakpoint($medium-up) {
              @include swap_direction(margin, 10px 28px);
            }
          }
        }
      }
    }
  }
}
