li.site-header__utility-item {
  &.lang-country-select {
    margin: 0 10px;
    padding: 0;
    &,
    & + li {
      float: left;
    }
  }
}

#countryPicker,
#languagePicker {
  width: auto;
  text-transform: initial;
  z-index: 999;
}

#countryPicker {
  ul.country-list {
    &,
    &-offsite {
      margin: 0;
      padding: 0;
    }
  }
}

#countryPickerLinkWrap,
#languagePickerLinkWrap {
  float: left;
  &:hover {
    #countryPickerLink,
    #languagePickerLink {
      z-index: 1000;
    }
  }
}

.language-list {
  padding-left: 0;
  list-style: none;
  li {
    &.selected {
      font-weight: bold;
    }
  }
}

#countryPickerWrap {
  .language-list,
  .country-list {
    li {
      min-width: 180px;
      padding: 0.6em 1.2em;
      text-align: left;
      cursor: pointer;
      a {
        transition: none;
      }
      &:hover {
        &,
        a {
          color: $color-white;
          background-color: $color-green;
        }
      }
    }
  }
}

ul#countryList {
  padding: 0;
  list-style: none;
}

ul.country-list-offsite {
  padding: 0;
  list-style: none;
}

#countryPickerLink,
#languagePickerLink {
  position: relative;
  top: 1px;
  width: auto;
  padding-right: 10px;
  margin-bottom: -1px;
  cursor: pointer;
  &:hover {
    color: $color-green;
  }
  img {
    display: block;
  }
}

.country-link {
  &,
  &:hover {
    border-bottom-color: transparent;
    text-decoration: none;
  }
  &.selected {
    font-weight: bold;
  }
}

.gnav-item-v1 {
  &#countryPickerLinkWrap:hover {
    #countryPicker {
      display: block;
    }
  }
  &#languagePickerLinkWrap:hover {
    #languagePicker {
      display: block;
    }
  }
  #countryPicker,
  #languagePicker {
    display: none;
    &.gnav-item-content {
      position: fixed;
      max-height: 100%;
      background-color: $white;
      border: none;
      box-shadow: 0 1px 5px $color-lighter-gray;
      text-decoration: none;
      text-align: left;
      list-style: none;
      cursor: pointer;
      overflow: auto;
    }
  }
}

.block-template-country-picker-eu-v1 {
  .current-lang {
    font-weight: bold;
  }
}

#localeOverlay {
  background: $color-box-shadow;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: none;
  position: fixed;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

#localeSwitchOverlay {
  background: $color-box-shadow;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: none;
  position: fixed;
  .column {
    padding: 1em;
    float: left;
  }
}

#localeSwitchWarning {
  position: relative;
  margin: auto;
  padding: 2em;
  font-size: 14px;
  line-height: 2em;
  width: 50% !important;
  max-width: 600px;
  text-align: center;
  background: $white;
  .column {
    float: right !important;
    width: 50%;
  }
  .btn-secondary {
    background: $white;
    color: $black;
  }
  .button {
    width: 80%;
    margin-top: 1em;
  }
}

#localeInterstitial {
  width: 80%;
  max-width: 800px;
  position: relative;
  top: 12%;
  background: $white;
  margin: auto;
  line-height: 2em;
  padding: 2px;
  text-align: center;
  .welcome-label {
    margin-bottom: 20px;
    font-family: $font--lamer-headline;
    font-size: 25px;
  }
  .column {
    padding: 1em;
    float: left;
  }
  .column,
  #localeSwitchWarning {
    .column {
      width: 50%;
    }
  }
  .initial-hide {
    display: none;
  }
  ul {
    font-size: 0; /* Fix for country options having whitespace between them */
    width: 100%;
    &.country-list {
      padding: 0;
      list-style-type: none;
    }
    &.country-list-offsite {
      padding: 0;
      list-style-type: none;
    }
    &.language-list {
      padding: 0;
      list-style-type: none;
      li {
        padding: 0;
        &:hover {
          color: $color-green;
          cursor: pointer;
        }
      }
    }
  }
  li {
    display: inline-block;
    width: 33%;
    float: none;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
  }
  .country-link {
    display: inline-block;
    line-height: 2em;
  }
  .label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }
  .inner-border {
    padding: 1.5em 6em;
  }
  input[type='radio'] {
    opacity: 1;
    margin: 0;
    position: relative;
    left: inherit;
  }
}

.country-list-offsite {
  li {
    width: 50%;
  }
}

.selected-lang {
  font-weight: bold;
}

.choose-country {
  font-size: 12px;
  margin: 0.5em 0;
}
/* Site Specific code */
.mobileCountryLink {
  display: none;
}

#changeLocaleButton {
  float: left;
  margin-left: 0.5em;
}

#cancelChangeLocaleButton {
  float: right;
  margin-right: 0.5em;
}

.page-header__nav__supplemental {
  display: inline-block;
  .block-template-gnav-account-v2 {
    float: right;
  }
  .block-template-country-picker-eu-v1 {
    float: left;
    margin-top: 14px;
    #countryPickerWrap {
      padding-right: 5px;
      .language-list {
        font-size: 14px;
        margin-right: 5px;
        li {
          margin-left: 1px;
          padding: 5px;
          line-height: 8px;
        }
      }
      #countryPicker {
        .country-list {
          font-size: 14px;
        }
      }
    }
  }
}

.page-header__meta-nav {
  div.node {
    float: none;
    @include breakpoint($landscape-up) {
      float: left;
    }
  }
  .utility-nav {
    &__item.country-chooser {
      @include breakpoint($landscape-up) {
        padding-top: 7px;
      }
      #countryPickerWrap {
        display: flex;
        .language-list {
          font-size: 15px;
          text-decoration: none;
        }
        #countryPickerLinkWrap {
          .country {
            color: $color-black;
          }
        }
      }
      .language-list {
        order: 2;
        padding-top: 5px;
        margin-right: 35px;
      }
      #countryPickerLink {
        order: 1;
      }
    }
  }
}
