.payment {
  .checkout {
    &__content {
      .checkout-panel {
        &__header {
          margin: 0 0 0.5em;
          .checkout-panel__heading {
            margin-bottom: 0;
          }
        }
        &__content {
          padding: 10px 0;
          border-bottom: 1px solid $color-light-gray;
          &--view-address {
            padding: 0;
            border-bottom: 0;
            .shipping-address {
              padding: 10px 0;
            }
          }
        }
        &--shipping {
          .shipping-address {
            padding-bottom: 10px;
            border-bottom: 1px solid $color-light-gray;
          }
        }
        .checkout-buttons-content {
          padding: 20px 0;
          overflow: auto;
        }
      }
    }
  }
}
